import React from "react";
import { Col, Row } from "react-bootstrap";
import {
    GiArtificialIntelligence
} from "react-icons/gi";
import {
  SiOracle
} from "react-icons/si";
import { SiKubernetes } from "react-icons/si";
import { SiTerraform } from "react-icons/si";

function Cert() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiOracle title="Oracle Cloud Infrastructure"/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <GiArtificialIntelligence  title="Machine Learning"/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiKubernetes title="Kubernetes"/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiTerraform title="Terraform"/>
      </Col>
    </Row>
  );
}

export default Cert;
