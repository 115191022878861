import React from "react";
import Techstack from "./Techstack";
import Cert from "./Cert";
import { Row, Container } from "react-bootstrap";
import Particle from "../Particle";


function Skills() {
    return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
      <h1 className="project-heading">
          Recently Used <strong className="purple">Languages </strong>
        </h1>
        <Row xs={1} md={2} style={{ justifyContent: "center", paddingBottom: "50px" , alignItems: "center", textAlign: "center"}}>
          <Row className="tech-icons">
          <img src="https://gitstats-ten.vercel.app/api/top-langs?username=anamaykashiv&layout=compact&bg_color=66000000&text_color=ffffff&card_width=450&hide_border=true&hide_title=true" alt="anamaykashiv" />  
          </Row>
        </Row>
        <h1 className="project-heading">
          Professional <strong className="purple">Skillset </strong>
        </h1>
        <Techstack />
        <h1 className="project-heading">
          Professional <strong className="purple">Certifications </strong>
        </h1>
        <Cert />

        </Container>
        </Container>
    );
}

export default Skills;
