import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiCss3,
  DiAndroid
} from "react-icons/di";
import {
  SiKubernetes,
  SiLinux,
  SiWindows10,
  SiAmazonaws,
  SiVisualstudiocode,
  SiMicrosoftazure,
  SiGit,
  SiGithub,
  SiGnubash,
  SiTailwindcss,
  SiApachemaven,
  SiJenkins,
  SiAnsible,
  SiTerraform,
  SiBootstrap,
  SiHtml5,
  SiDocker,
  SiPython,
  SiMysql


} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <CgCPlusPlus title="C++"/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAmazonaws title="AWS" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiCss3 title="CSS" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiLinux title="Linux" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiWindows10 title="Windows" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiAndroid title="Android" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGit title="Git" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGithub title="Github" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGnubash title="bash" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiTailwindcss title="Tailwind" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiApachemaven title="Apache Maven" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiJenkins title="Jenkins" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAnsible title="Ansible" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiTerraform title="Terraform" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiBootstrap title="Bootstrap" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiHtml5 title="HTML" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDocker title="Docker" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPython title="Python" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMysql title="MySQL" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiKubernetes title="Kubernetes" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMicrosoftazure title="Azure" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode title="VS Code" />
      </Col>

    </Row>
  );
}

export default Techstack;
